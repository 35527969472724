import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { updatedTerms } from "./terms";
import SignatureCanvas from "react-signature-canvas";
import { generateTermsAndConditionsDoc } from "../../pdfTermsGenerator";
import { toast } from "react-toastify";
import Checkbox from "src/components/shared/Checkbox";
import { useContext } from "react";
import { AuthUserContext } from "src/components/Session";
import { downloadPDF } from "src/components/ViewClassroom/components/DataTable/components/GetReactPDF";
import TermsAndConditionsPDF from "./pdf";

const TermsAndConditions = ({
  firebase,
  termsAndConditionsAccepted,
  fromDiversion,
}) => {
  const user = useContext(AuthUserContext);
  const [acceptedTerms, setAcceptedTerms] = useState(updatedTerms);
  const [open, setOpen] = useState(true);
  const [contractReady, setContractReady] = useState(false);
  const [openFileUrl, setOpenFileUrl] = useState(null);
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [signed, setSigned] = useState(false);
  const [allTermsChecked, setAllTermsChecked] = useState(false);
  const [legalName, setLegalName] = useState(user.displayName || "");

  const [currentTerm, setCurrentTerm] = useState(updatedTerms[0]);

  useEffect(() => {
    console.log("TermsAndConditions 27 | updated user", user.displayName);
    setLegalName(user.displayName);
  }, [user.displayName]);

  const handleSigning = async () => {
    if (contractReady) {
      window.open(openFileUrl, "_blank");
      return;
    }

    setLoadingFileUpload(true);
    let signatureImageBase64 = await signatureRef.current
      .getTrimmedCanvas()
      .toDataURL();

    try {
      const signatureBlob = await fetch(signatureImageBase64).then(r => r.blob());

      const signedDocumentLocation = await generateTermsAndConditionsDoc(
        legalName,
        signatureImageBase64
      );

      downloadPDF({
        document: (
          <TermsAndConditionsPDF
            username={user.displayName}
            documentTitle={user.displayName}
            signatureImage={signatureImageBase64}
          />
        ),
        save: true,
        fileName: `${user.displayName} LSI Waiver.pdf`,
      })
        .then(async (res) => {
          console.log("res", res);
          const signedDoc = await res.blob;
          await firebase.userSignature(user.uid).put(signatureBlob);
          await firebase.userLSITermsAndConditions(user.uid).put(signedDoc);
          await firebase.user(user.uid).update({
            termsAndConditionsAccepted: true,
            displayName: legalName,
            username: legalName,
          });

          toast.success("Document Signed Correctly and Legal Name Updated!");

          setOpenFileUrl(signedDocumentLocation);
          setContractReady(true);
          setSigned(true);
          setLoadingFileUpload(false);
        })
        .catch((err) => {
          console.log(err, "err");
        });

    } catch (error) {
      console.log("termsAndConditions.js 245 | upload error", error);
    }

    console.log("termsAndConditions.js 212 | signature", signatureImageBase64);
  };
  const signatureRef = useRef(null);

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const handleChange = (index) => {
    let acceptedTermsCopy = [...acceptedTerms];
    acceptedTermsCopy[index].accepted = !acceptedTermsCopy[index].accepted;
    setAcceptedTerms(acceptedTermsCopy);
    setCurrentTerm(acceptedTerms[index]);
  };

  const handleAllTermsChecked = () => {
    const acceptedTermsChecker = acceptedTerms.every((term) => term.accepted);
    console.log("TermsAndConditions 74 | accepted terms", acceptedTermsChecker);
    if (acceptedTerms.every((term) => term.accepted)) {
      setAllTermsChecked(true);
      toast.success("All terms read");
    } else {
      toast.error(
        "Please read all terms and conditions to enable this agreement checkbox.",
        { position: "top-center" }
      );
    }
  };

  const disabled = () => {
    return allTermsChecked === false || signed === false || !legalName;
  };

  if (!open) return null;

  return (
    <div className="termsandconditionsmodal__container">
      <div className="termsandconditionsmodal__content">
        <div className="termsandconditionsmodal__title">
          Accept Terms and Conditions
        </div>
        <div className="termsandconditionsmodal__header">
          <div className="header__title">
            Rehabilitation Enables Dreams Terms and Conditions End User
            Agreement ("EULA")
          </div>

          {termsAndConditionsAccepted && user.isAdmin && (
            <div
              className="headerclose__button"
              onClick={() => {
                setOpen(false);
              }}
            >
              X
            </div>
          )}
        </div>
        <div className="termsandconditionsmodal__termsbox">
          <div className="currentterm__title">{currentTerm.title}</div>
          <div>{currentTerm.content}</div>
        </div>

        <div className="checkboxterms__container">
          <Checkbox
            isChecked={allTermsChecked}
            onClick={handleAllTermsChecked}
          />
          <div className="checkbox__terms">
            By checking the box, I am confirming that I have read, fully
            understand, agree to follow, and provide my consent relating to the
            terms and conditions outlined in each of following documents (click
            to review each document):
          </div>
        </div>
        <div className="termsandconditionsmodalterms__container">
          {acceptedTerms.map((term, index) => {
            return (
              <div
                key={index}
                className="termsandconditionsacceptterms__container"
              >
                <div className="termsandconditionsterms__text">
                  {term.title}.
                </div>
                <div
                  className={
                    !term.accepted
                      ? "clicktoread__button"
                      : "alreadyread__button"
                  }
                  onClick={() => !term.accepted && handleChange(index)}
                >
                  {!term.accepted ? "Click to Read" : "Read"}
                </div>
              </div>
            );
          })}
        </div>
        <div className="legalnameandphone__container">
          <div className="legalinputs__container">
            <div className="legalinputs__title">Legal Name</div>
            <input
              className="legalinputs__input"
              placeholder="Legal Name"
              value={legalName}
              onChange={(e) => setLegalName(e.target.value)}
            />
          </div>
        </div>
        <div className="legalinfo__warning">
          IMPORTANT: Input your FULL LEGAL NAME as it appears on your{" "}
          <span>Driver’s License or State-Issued ID</span> to avoid{" "}
          <span>LEGAL ISSUES</span>
        </div>
        <div className="termsandconditionsmodal__signaturecanvas">
          <div className="signature__container__terms">
            <SignatureCanvas
              penColor="black"
              backgroundColor="rgb(184,184,184)"
              ref={signatureRef}
              canvasProps={{
                width: 454,
                height: 150,
              }}
              onEnd={() => {
                console.log("termsAndConditions.js 206 | signature end");
                setSigned(true);
              }}
            />
            <div className="clearsignature__text" onClick={clearSignature}>
              Clear Signature
            </div>
            <div className="signhere__text">Sign here</div>
          </div>
        </div>

        <div className="termsandconditionsmodalbutton__container">
          <button
            className="termsandconditionsmodalsubmit__button"
            disabled={disabled()}
            onClick={handleSigning}
          >
            {loadingFileUpload
              ? "Loading..."
              : disabled()
                ? `Missing ${!allTermsChecked ? "Terms Checkbox," : ""} ${!legalName ? "Legal Name," : ""
                } ${!signed ? "Signature" : ""} `
                : openFileUrl
                  ? "Review Contract"
                  : "Submit"}
          </button>
          {openFileUrl && (
            <button
              className="termsandconditionsmodalsubmit__button"
              style={{ marginLeft: 30 }}
              onClick={() => {
                setOpen(false);
                fromDiversion && fromDiversion();
              }}
            >
              {fromDiversion ? "Next" : "Start Assessment"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
