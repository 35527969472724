import React, { Component, useState } from "react";

import { withFirebase } from "../Firebase";
import { withAuthorization, AuthUserContext } from "../Session";

import { compose } from "recompose";

import { CSSTransition } from "react-transition-group";
import ReactCSSTransitionGroup from "react-addons-css-transition-group"; // ES6

import * as ROLES from "../../constants/roles";

import "./index.css";

import EditSectionForm from "../EditSectionForm";
import ViewSection from "../ViewSection";
import SectionsNavBar from "./components/SectionsNavBar";
import { AdminOptions } from "./components/AdminOptions";
import {
  copiedCurrentTreeBranch,
  openNotification,
  pastedCopiedTreeBranch,
} from "../../utils/notifications";
import UpdateSectionsOrderButton from "./components/UpdateSectionOrderButton";
import { sendOneEmail } from "src/api/emails";
import { completedModule } from "src/api/emailTemplates";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { getSectionsByLessonId } from "src/store/lesson/lessonActions";

export class ViewLessonPage extends Component {
  static contextType = AuthUserContext;
  state = {
    module: {},
    sections: [], //every section
    currentSectionAdd: {
      content: [],
    },
    userMode: this.context.isAdmin ? "ADMIN" : "user",
    viewMode: "admin",
    currentSection: {}, // view
    currentSectionEdit: {},
    mode: "", //add, edit,
    currentSectionNumber: 0,
    switched: false,
    loadedSections: false,
    progress: 0,
    copiedTreeBranch: null,
    finishLoading: false,
    timesTried: 0,
    updatingOrder: false,
    type: null,
    newSectionAnimationIndex: null,
    sectionStartTime: null,
  };

  componentDidMount() {
    this.getClassData();
    this.trackSectionEntry();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.timesTried !== this.state.timesTried) {
      return false;
    }
    return true;
  }

  getClassData = async () => {
    await this.props.firebase
      .classroom(this.context.classUID)
      .once("value", (snapshot) => {
        let classroomData = snapshot.val();
        console.log("hey classroomData", classroomData);
        if (classroomData) {
          this.setState({ classroomData: classroomData });
        }
      });

    this.getLessonSectionsByIdFromQueryParams();
    this.getCurrentUserSectionProgress();
  };

  getCurrentUserSectionProgress = () => {
    const { lessonId } = this.props.match.params;
    const { progress } =
      this.context && this.context.lessons
        ? this.context.lessons[lessonId]
          ? this.context.lessons[lessonId]
          : 0
        : 0;
    const currentProgress = typeof progress === "undefined" ? 0 : progress;
    const sectionLength =
      this.context &&
      this.context.lessons &&
      this.context.lessons[lessonId] &&
      this.context.lessons[lessonId].sectionLength;
    this.setState({
      currentSectionNumber:
        this.context && this.context.lessons
          ? currentProgress === sectionLength
            ? currentProgress - 1
            : currentProgress
          : 0,
      progress: this.context && this.context.lessons ? currentProgress : 0,
    });
  };

  createPreModuleQuizzes = (sections) => {
    const preModuleAssessment = sections.find(
      (section) => section.name === "Pre-Module Assessment"
    );

    const preModuleQuizzes = {
      content: sections
        .flatMap((section) => section.content || [])
        .filter((content) => content.type === "quiz"),
      name: "Pre-Module Quizzes",
      index: preModuleAssessment?.index,
      uid: preModuleAssessment?.index.toString(),
    };

    const { classroomData } = this.state;

    let newSections = sections?.map((section) => {
      if (
        section.name === "Pre-Module Assessment" &&
        classroomData?.settings?.preModuleQuizzesAvailable
      ) {
        return preModuleQuizzes;
      } else {
        return section;
      }
    });

    if (preModuleQuizzes.content.length === 0) {
      return sections;
    } else {
      return newSections;
    }
  };

  getLessonSectionsByIdFromQueryParams = () => {
    const { lessonId } = this.props.match.params;
    this.props.firebase.sections(lessonId).on("value", (snapshot) => {
      if (snapshot.val()) {
        const sectionsObject = snapshot.val().sections;
        const sectionsListFromDb = sectionsObject
          ? Object.keys(sectionsObject).map((key, index) => ({
            ...sectionsObject[key],
            uid: key,
            index: index,
          }))
          : null;

        // This function needs to update the state with the categories that the user passed on the pre-test
        //TODO need an extra conditional here to handle if it's an admin or a user

        // preModuleQuizzesAvailable

        this.setState({
          sections: this.createPreModuleQuizzes(sectionsListFromDb),
          currentSection: sectionsListFromDb ? sectionsListFromDb[0] : null,
          mode: "view",
          loadedSections: true,
          name: snapshot.val().name,
          description: snapshot.val().description,
          type: snapshot.val().type,
          imageUrl: snapshot.val().image,
        });
      } else {
        console.log("ViewLessonPage 125 | no sections yet");
      }
    });
  };
  addSectionContent = (type) => {
    console.log("ViewLessonPage 175 | adding section content", type);
    let newFinanceTypes =
      type.split("-")?.[0] === "newFinanceSpreadsheets" ? true : false;
    //TODO refactor this crap
    console.log("ViewLessonPage 178 | adding section content", type);
    let content = { type: type, value: "" };
    if (type === "text") {
      content = { type: type, value: "" };
    } else if (type === "text-slate") {
      console.log("ViewLessonPage 131 | adding slate text");
      content = {
        type: type,
        textValue: [
          {
            type: "paragraph",
            children: [
              {
                text: "Add text here...",
              },
            ],
          },
        ],
      };
    } else if (type === "image") {
      content = { type: type, url: "" };
    } else if (type === "video") {
      content = { type: type, url: "" };
    } else if (type === "quiz") {
      content = {
        type: "quiz",
        question: "",
        answer: "",
        options: ["", "", "", "", ""],
      };
    } else if (type === "multi-dragdrop") {
      content = {
        type: "multi-dragdrop",
        wordsDefsInputs: [
          {
            word: "",
            definitions: ["", ""],
          },
          {
            word: "",
            definitions: ["", ""],
          },
        ],
      };
    } else if (type === "drag-drop") {
      content = {
        type: "drag-drop",
        wordsAndDefs: [
          { word: "", definition: "" },
          { word: "", definition: "" },
          { word: "", definition: "" },
          { word: "", definition: "" },
        ],
      };
    } else if (type === "likert") {
      content = {
        type: "likert",
        question: "",
        category: "",
      };
    } else if (type === "file") {
      content = {
        type: "file",
        url: "",
      };
    } else if (newFinanceTypes) {
      content = {
        type: "newFinanceSpreadsheets",
        value: type.split("-")?.[1],
      };
    } else if (type === "carousel") {
      content = {
        type: "carousel",
        slides: [
          { slideName: "Slide 1", value: "" },
          { slideName: "Slide 2", value: "" },
          { slideName: "Slide 3", value: "" },
        ],
      };
    } else if (type === "table") {
      content = {
        type: "table",
        columns: ["Column 1", "Column 2"],
        rows: [
          ["val 1", "val 2"],
          ["val 1.1", "val 1.2"],
        ],
      };
    } else if (type === "chatGame") {
      content = {
        type: "chatGame",
        situations: [
          {
            situation: "You were speeding. A police officer stops you. You...",
            actions: [
              {
                action: "Hit the Gas!",
                response: "The officer calls for support, you get arrested",
                danger: 100,
              },
              {
                action: "Hit the breaks!",
                response: "You slow down and stop. The officer approaches.",
                danger: -15,
              },
            ],
          },
          {
            situation: "The officer asks you for your documents. You...",
            actions: [
              {
                action:
                  "Tell the officer you/'re reaching for your documents and hand them over.",
                response:
                  "The officer receives the documents and tells you everythig is in order",
                danger: -20,
              },
              {
                action: "You refuse, demanding an explanation",
                response:
                  "The officer raises his voice asking you to calm down.",
                danger: 30,
              },
            ],
          },
        ],
      };
    } else if (type === "decisionTree") {
      content = {
        type: "decisionTree",
        tree: {
          id: 1,
          parentId: null,
          actionText: null,
          cost: null,
          revenue: null,
          progress: null,
          resultText: "First Situation",
          childrenSituations: [
            {
              id: 2,
              parentId: null,
              actionText: null,
              cost: null,
              revenue: null,
              progress: null,
              resultText: "Second Situation",
              childrenSituations: [],
            },
          ],
        },
      };
    } else if (type === "user-input") {
      content = {
        type: "user-input",
        prompt: "",
        answer: "",
        placeholder: "",
      };
    }

    if (this.state.currentSectionEdit.content) {
      console.log("ViewLessonPage 341 | adding content to current section edit", content);
      console.log("ViewLessonPage 342 | current section edit", this.state.currentSectionEdit);
      this.setState({
        currentSectionEdit: {
          ...this.state.currentSectionEdit,
          content: [...this.state.currentSectionEdit.content, content],
        },
      });
    } else {
      this.setState({
        currentSectionEdit: {
          ...this.state.currentSectionEdit,
          content: [content],
        },
      });
    }
  };

  editSection = (
    e,
    index,
    type,
    optionIndex,
    url,
    dragDropType,
    likertType,
    chatType,
    actionIndex,
    treeInfo,
    tableType,
    columnIndex,
    rowIndex,
    rowValueIndex
  ) => {
    if (type === "quiz") {
      let newContent = [...this.state.currentSectionEdit.content];

      // Handle the case where we're updating the entire quiz object at once
      if (e.target.name === "quiz") {
        newContent[index] = e.target.value; // e.target.value contains the entire new quiz object
        this.setUpdatedContent(newContent);
        return;
      }

      // Handle individual field updates as before
      if (e.target.name === "question" || e.target.name === "answer" || e.target.name === "category") {
        newContent[index][e.target.name] = e.target.value;
      } else if (e.target.name === "option") {
        newContent[index].options[optionIndex] = e.target.value;
      }

      this.setUpdatedContent(newContent);
    } else if (type === "user-input") {
      let newContent = [...this.state.currentSectionEdit.content];
      console.log("newContent", newContent, e, index, type);
      if (e.target.name === "question") {
        newContent[index].prompt = e.target.value;
      }
      if (e.target.name === "placeholder") {
        newContent[index].placeholder = e.target.value;
      }
      // newContent[index].url = url;
      // this.setUpdatedContent(newContent);
    } else if (type === "file") {
      let newContent = [...this.state.currentSectionEdit.content];
      newContent[index].url = url;
      this.setUpdatedContent(newContent);
    } else if (type === "text-slate") {
      let contentCopy = [...this.state.currentSectionEdit.content];

      contentCopy[index].textValue = e.target.value;

      this.setUpdatedContent(contentCopy);
    } else if (type === "text") {
      console.log("ViewLessonPage 405 | editing text", e);
      let newContentCopy = [...this.state.currentSectionEdit.content];
      newContentCopy[index].value = e;
      this.setUpdatedContent(newContentCopy);
    } else if (type === "drag-drop") {
      let newContent = [...this.state.currentSectionEdit.content];
      if (dragDropType === "word") {
        newContent[index].wordsAndDefs[optionIndex].word = e;
        newContent[index].wordsAndDefs[optionIndex].show = true;
      }
      if (dragDropType === "definition") {
        newContent[index].wordsAndDefs[optionIndex].definition = e;
        newContent[index].wordsAndDefs[optionIndex].show = true;
      }

      this.setUpdatedContent(newContent);
    } else if (type === "text-input") {
    } else if (type === "likert") {
      let newContent = [...this.state.currentSectionEdit.content];
      if (likertType === "question") {
        newContent[index].question = e.target.value;
      }
      if (likertType === "category") {
        newContent[index].category = e;
      }
      this.setUpdatedContent(newContent);
    } else if (type === "carousel") {
      let newContent = [...this.state.currentSectionEdit.content];
      newContent[index].slides[optionIndex].value = e;
      this.setUpdatedContent(newContent);
    } else if (type === "table") {
      let newContent = [...this.state.currentSectionEdit.content];
      if (tableType === "addColumn") {
        if (!newContent[index].columns || !newContent[index].rows) {
          newContent[index].columns = ["Column 1"];
          newContent[index].rows = [["Row 1"]];
        }
        if (newContent[index].columns.length > 4) return;
        newContent[index].columns.push("New Col");

        newContent[index].rows.forEach((row, rowIndex) => {
          newContent[index].rows[rowIndex].push("");
        });
      }
      if (tableType === "removeColumn") {
        newContent[index].columns.pop();
        newContent[index].rows.forEach((row, rowIndex) => {
          newContent[index].rows[rowIndex].pop();
        });
      }
      if (tableType === "addRow") {
        const rowsToPush = newContent[index].columns.map((col) => "New Row");
        newContent[index].rows.push(rowsToPush);
      }
      if (tableType === "removeRow") {
        newContent[index].rows.pop();
      }
      if (tableType === "editColumn") {
        newContent[index].columns[columnIndex] = e.target.value;
      }
      if (tableType === "editRow") {
        newContent[index].rows[rowIndex][rowValueIndex] = e.target.value;
      }

      this.setUpdatedContent(newContent);
    } else if (type === "chatGame") {
      let newContent = [...this.state.currentSectionEdit.content];
      if (chatType === "action") {
        newContent[index].situations[optionIndex].actions[actionIndex].action =
          e.target.value;
        // this.setUpdatedContent(newContent);
      } else if (chatType === "response") {
        newContent[index].situations[optionIndex].actions[
          actionIndex
        ].response = e.target.value;
      } else if (chatType === "danger") {
        newContent[index].situations[optionIndex].actions[actionIndex].danger =
          e.target.value;
      } else {
        newContent[index].situations[optionIndex].situation = e.target.value;
      }
      this.setUpdatedContent(newContent);
    } else if (type === "decisionTree") {
      let newContent = [...this.state.currentSectionEdit.content];

      const { type, chosenChildrenTracker, responseIndex, treeIndex } =
        treeInfo;

      let currentTreeNode = newContent[index].tree;

      for (let level = 0; level < treeIndex; level++) {
        currentTreeNode =
          currentTreeNode.childrenSituations[chosenChildrenTracker[level]];
      }

      if (type === "goal") {
        newContent[index].gameGoal = e.target.value;
      }

      if (type === "initialBank") {
        newContent[index].initialBank = e.target.value;
      }

      if (type === "situation") {
        currentTreeNode.resultText = e.target.value;
      }
      if (type === "response") {
        currentTreeNode.childrenSituations[responseIndex].actionText =
          e.target.value;
      }
      if (type === "cost") {
        console.log("ViewLessonPage 532 | editing tree node", currentTreeNode);
        currentTreeNode.childrenSituations[responseIndex].cost = e.target.value;
      }
      if (type === "revenue") {
        currentTreeNode.childrenSituations[responseIndex].revenue =
          e.target.value;
      }
      if (type === "progress") {
        currentTreeNode.childrenSituations[responseIndex].progress =
          e.target.value;
      }
      if (type === "addSituation") {
        if (currentTreeNode.childrenSituations) {
          currentTreeNode.childrenSituations.push({
            id: 2,
            parentId: 1,
            actionText: `New Action Text ${Math.floor(Math.random() * 10)}.`,
            cost: 0,
            revenue: 0,
            progress: 0,
            resultText: `New Result ${Math.floor(Math.random() * 10)}`,
          });
        } else {
          currentTreeNode.childrenSituations = [
            {
              id: 2,
              parentId: 1,
              actionText: `New Action Text. ${Math.floor(Math.random() * 10)}`,
              cost: 0,
              revenue: 0,
              progress: 0,
              resultText: `New Result Text ${Math.floor(Math.random() * 10)}`,
            },
          ];
        }
      }
      if (type === "deleteLastSituation") {
        currentTreeNode.childrenSituations.pop();
      }

      if (type === "copyExistingBranch") {
        copiedCurrentTreeBranch(
          currentTreeNode.childrenSituations[treeInfo.childSituationIndex]
            .actionText
        );

        let branchCopy = { ...currentTreeNode };

        this.setState({
          copiedTreeBranch:
            branchCopy.childrenSituations[treeInfo.childSituationIndex],
        });
      }

      if (type === "pasteExisting") {
        const repeatedBranch = currentTreeNode.childrenSituations
          ? currentTreeNode.childrenSituations.filter(
            (child) =>
              child.actionText === this.state.copiedTreeBranch.actionText
          )
          : [];

        if (repeatedBranch.length > 0) {
          openNotification(
            "REPEATED",
            "The branch you are trying to paste is already included in this tree branch"
          );
        } else {
          pastedCopiedTreeBranch();
          const copiedBranchCopy = { ...this.state };

          if (currentTreeNode.childrenSituations) {
            currentTreeNode.childrenSituations.push(
              copiedBranchCopy.copiedTreeBranch
            );
          } else {
            currentTreeNode.childrenSituations = [
              copiedBranchCopy.copiedTreeBranch,
            ];
          }

          this.setState({
            copiedTreeBranch: null,
          });
        }
      }
      this.setUpdatedContent(newContent);
    } else if (type === "snowball-spreadsheet") {
      let newContent = [...this.state.currentSectionEdit.content];
      console.log("ViewLessonPage 623 | editing snowball spreadsheet");

      if (e.target.name === "add-content") {
        // add content button
        console.log("ViewLessonPage 630 | adding checker", newContent);
        if (newContent[index].data) {
          console.log("ViewLessonPage 633 | there is data", newContent[index]);
          newContent[index].data.push({
            answer: ["Answer"],
            instructions: "",
            range: "A1",
            text: "Type text here",
            title: "Type Title here",
            hint: "Type (answer) on cell (cell)",
            format: {
              backgroundColor: "#FFFFFF",
              bold: true,
              fontColor: "#000000",
            },
          });
        } else {
          newContent[index].data = [
            {
              answer: ["Answer"],
              instructions: "",
              range: "A1",
              text: "Type text here",
              title: "Type Title here",
              format: {
                backgroundColor: "#FFFFFF",
                bold: true,
                fontColor: "#000000",
              },
              hint: "Type (answer) on cell (cell)",
            },
          ];
        }
      }

      if (e.target.name === "answer") {
        newContent[index].data[optionIndex][e.target.name][0] = e.target.value;
      }
      if (
        e.target.name === "title" ||
        e.target.name === "hint" ||
        e.target.name === "range" ||
        e.target.name === "text" ||
        e.target.name === "instructions"
      ) {
        newContent[index].data[optionIndex][e.target.name] = e.target.value;
      }

      if (e.target.name === "add-format") {
        if (newContent[index].formats) {
          newContent[index].formats.push({
            backgroundColor: "#FFFFFF",
            bold: true,
            fontColor: "#000000",
            range: "A1:B5",
          });
        } else {
          newContent[index].formats = [
            {
              backgroundColor: "#FFFFFF",
              bold: true,
              fontColor: "#000000",
              range: "A1:B5",
            },
          ];
        }
      }

      if (
        // FORMAT NAMES
        e.target.name === "formatRange" ||
        e.target.name === "value" ||
        e.target.name === "fontColor" ||
        e.target.name === "backgroundColor" ||
        e.target.name === "fontWeight"
      ) {
        newContent[index].formats[optionIndex][e.target.name] = e.target.value;
      }

      this.setUpdatedContent(newContent);
    } else if (type === "image-width") {
      let newContent = [...this.state.currentSectionEdit.content];
      newContent[index].imageWidth = e.target.value;
      this.setUpdatedContent(newContent);
    } else {
      let newContent = [...this.state.currentSectionEdit.content];
      console.log("ViewLessonPage 595 | setting content url on else statement");
      newContent[index].url = e.target.value; //e because I'm using React Quill, where e is the value
      this.setUpdatedContent(newContent);
    }
  };

  _moveSectionContentDown = (e, currentIndex) => {
    if (currentIndex === this.state.currentSectionEdit.content.length - 1) {
      return;
    }
    const newItemsOrder = { ...this.state.currentSectionEdit };

    [
      newItemsOrder.content[currentIndex],
      newItemsOrder.content[currentIndex + 1],
    ] = [
        newItemsOrder.content[currentIndex + 1],
        newItemsOrder.content[currentIndex],
      ];

    this.setState({
      currentSectionEdit: newItemsOrder,
    });
  };

  _moveSectionContentUp = (e, currentIndex) => {
    if (currentIndex === 0) return;
    const newItemsOrder = { ...this.state.currentSectionEdit };
    //reorder
    [
      newItemsOrder.content[currentIndex],
      newItemsOrder.content[currentIndex - 1],
    ] = [
        newItemsOrder.content[currentIndex - 1],
        newItemsOrder.content[currentIndex],
      ];

    this.setState({
      currentSectionEdit: newItemsOrder,
    });
  };

  _insertNewSectionAtIndex = () => {
    const sectionsCopy = [...this.state.sections];
    const currentSectionEdit = { ...this.state.currentSectionEdit };

    if (currentSectionEdit !== {}) {
      sectionsCopy.splice(currentSectionEdit.index, 0, {
        name: `New Section ${Math.random() * 100}`,
        index: currentSectionEdit.index,
        uid: `${currentSectionEdit.index}`,
      });

      sectionsCopy.forEach((section, index) => {
        //if the index is higher, increase by one
        if (index > currentSectionEdit.index) {
          sectionsCopy[index].index++;
          sectionsCopy[index].uid++;
        }
      });

      this.setState({ sections: sectionsCopy });
    }
  };

  _addSituation = (index) => {
    let newContent = [...this.state.currentSectionEdit.content];
    newContent[index].situations.push({
      situation: "New Situation",
      actions: [
        { action: "New Action 1", response: "New response", danger: 10 },
        { action: "New Action 2", response: "New response 2", danger: -10 },
      ],
    });
    this.setUpdatedContent(newContent);
  };

  _removeSituation = (index) => {
    let newContent = [...this.state.currentSectionEdit.content];
    newContent[index].situations.pop();
    this.setUpdatedContent(newContent);
  };

  _addActionResponseDanger = (index, situationIndex) => {
    let newContent = [...this.state.currentSectionEdit.content];

    newContent[index].situations[situationIndex].actions.push({
      action: "Action Added",
      response: "Response",
      danger: 0,
    });
    this.setUpdatedContent(newContent);
  };

  _removeActionResponseDanger = (index, situationIndex) => {
    let newContent = [...this.state.currentSectionEdit.content];
    newContent[index].situations[situationIndex].actions.pop();
    this.setUpdatedContent(newContent);
  };

  addSlide = (index) => {
    let newContent = [...this.state.currentSectionEdit.content];
    console.log("ViewLessonPage 817 | ");
    if (newContent[index].slides) {
      newContent[index].slides.push({ slideName: "New slide", value: "" });
    } else {
      newContent[index].slides = [{ slideName: "New slide", value: "" }];
      console.log("ViewLessonPage 821 | no slides");
    }

    this.setUpdatedContent(newContent);
  };

  setUpdatedContent = (newContent) => {
    console.log("ViewLessonPage 716 | setting updated content", newContent);
    this.setState({
      currentSectionEdit: {
        ...this.state.currentSectionEdit,
        content: newContent,
      },
    });
  };

  removeSlide = (index, slideIndex) => {
    let newContent = [...this.state.currentSectionEdit.content];
    if (newContent[index].slides) {
      newContent[index].slides.pop();
    } else {
      console.log("ViewLessonPage 842 | no slides to pop");
    }

    this.setUpdatedContent(newContent);
  };

  addSection = (e) => {
    e.preventDefault();
    const { lessonId } = this.props.match.params;
    this.props.firebase
      .pushSection(lessonId)
      .push({
        ...this.state.currentSectionAdd,
        creatorName: this.context?.displayName,
        creatorEmail: this.context?.email,
        content: [],
      })
      .then(() => {
        this.setState({
          mode: "view",
          ...this.state.currentSectionEdit,
        });
      })
      .catch((error) => console.log("error updating form", error));
  };

  onSectionChange = (e) => {
    this.setState({
      currentSectionEdit: {
        ...this.state.currentSectionEdit,
        [e.target.name]: e.target.value,
      },
    });
  };

  toggleAdd = () => {
    this.setState({
      mode: "add",
    });
  };

  toggleUserViewMode = (index, section) => {
    this.setState({
      currentSectionNumber: index,
      currentSection: section,
      currentSectionEdit: section,
      mode: "view",
    });
  };

  toggleEditMode = (section) => {
    this.setState({
      currentSectionEdit: JSON.parse(JSON.stringify(section)),
      uid: section.uid,
      mode: "edit",
    });
  };

  onChange = (e) => {
    this.setState({
      currentSectionAdd: {
        ...this.state.currentSectionAdd,
        [e.target.name]: e.target.value,
      },
    });
  };

  removeSectionContentItem = (e, index) => {
    e.preventDefault();
    let updatedContent = [...this.state.currentSectionEdit.content];
    updatedContent.splice(index, 1);
    this.setState({
      currentSectionEdit: {
        ...this.state.currentSectionEdit,
        content: updatedContent,
      },
    });
  };

  removeSection = (uid) => {
    const { lessonId } = this.props.match.params;
    this.props.firebase
      .removeSection(lessonId, uid)
      .remove()
      .then(() => {
        this.setState({
          currentSectionAdd: {},
          mode: "",
          currentSection: {},
          currentSectionEdit: {},
        });
      })
      .catch((error) =>
        console.log("ViewLessonPage.js 275 | error removing section", error)
      );
  };

  onSectionSubmit = (e) => {
    e.preventDefault();

    const { lessonId } = this.props.match.params;

    this.props.firebase.updateSection(lessonId, this.state.uid).update({
      name: this.state.currentSectionEdit.name,
      ...this.state.currentSectionEdit,
    });

    this.props.firebase
      .updateModuleOverview(lessonId)
      .update({ sectionsLength: this.state.sections.length });

    this.setState({
      mode: "view",
      currentSection: { ...this.state.currentSectionEdit },
    });
  };

  toggleAdminAndUserViews = () => {
    const { userMode } = this.state;
    this.setState({
      userMode: userMode === "ADMIN" ? "user" : "ADMIN",
    });
  };

  nextSection = () => {
    window.scrollTo(0, 0);
    const { currentSectionNumber, sections } = this.state;
    console.log('nextSection state.currentSectionNumber 1', this.state.currentSectionNumber);

    if (currentSectionNumber < sections.length - 1) {
      // First update the state
      this.setState({
        currentSectionNumber: currentSectionNumber + 1,
        currentSection: sections[currentSectionNumber + 1],
        videosDone: false
      }, () => {
        console.log('nextSection state.currentSectionNumber 2', this.state.currentSectionNumber);
        // Then update progress after state has been updated
        this.updateUserLessonProgress();
      });
    }
  };

  prevSection = () => {
    window.scrollTo(0, 0);
    const { currentSectionNumber, sections } = this.state;

    if (currentSectionNumber > 0) {
      // Update progress with current section's time before moving to previous
      this.updateUserLessonProgress().then(() => {
        this.setState({
          currentSectionNumber: currentSectionNumber - 1,
          currentSection: sections[currentSectionNumber - 1],
          videosDone: true
        });
      });
    }
  };

  trackSectionEntry = () => {
    console.log('Starting section timer');
    this.setState({ sectionStartTime: Date.now() });
  };

  getSectionTimeSpent = () => {
    if (!this.state.sectionStartTime) {
      console.log('No start time recorded');
      return 0;
    }
    const timeSpent = Date.now() - this.state.sectionStartTime;
    console.log('Time spent:', timeSpent, 'ms');
    return timeSpent;
  };

  updateUserLessonProgress = async (unmounted) => {
    const { lessonId } = this.props.match.params;
    const { firebase } = this.props;
    const { lessons } = this.context;

    // Get the time spent in current section
    const timeSpentInSection = this.getSectionTimeSpent();
    console.log('Updating progress. Time spent:', timeSpentInSection);

    let lessonCompletions = lessons && lessons[lessonId]?.sectionsCompletionTracker;
    let newCompletions = {};
    const sectionKey = this.state.currentSectionNumber.toString();

    if (lessonCompletions) {
      const existingSection = lessonCompletions[sectionKey] || {};
      const previousTimeSpent = existingSection.totalTimeSpent || 0;

      newCompletions = {
        ...lessonCompletions,
        [sectionKey]: {
          lastCompletionDate: Date.now(),
          device: "desktop",
          totalTimeSpent: previousTimeSpent + timeSpentInSection,
          attempts: (existingSection.attempts || 0) + 1
        }
      };
    } else {
      newCompletions[sectionKey] = {
        lastCompletionDate: Date.now(),
        device: "desktop",
        totalTimeSpent: timeSpentInSection,
        attempts: 1
      };
    }

    try {
      console.log('updateUserLessonProgress state.currentSectionNumber', this.state.currentSectionNumber);
      await firebase
        .updateUserLessonProgress(this.context.uid, lessonId)
        .update({
          ...(!unmounted ? { progress: this.state.currentSectionNumber } : {}),
          sectionLength: this.state.sections.length,
          name: this.state.name || "",
          description: this.state.description || "",
          imageUrl: this.state.imageUrl || "",
          sectionsCompletionTracker: newCompletions,
          deviceUse:
            !this.context.lessons[lessonId]?.deviceUse ||
              this.context.lessons[lessonId]?.deviceUse === "desktop"
              ? "desktop"
              : "app/desktop",
        });

      // Start timing the next section
      if (!unmounted) {
        this.trackSectionEntry();
      }

    } catch (error) {
      console.log("Error updating user progress:", error);
    }
  };

  cancelEdit = () => {
    this.setState({
      mode: "view",
    });
  };

  toggleFinishLoading = (isLoading) => {
    // this.setState({ finishLoading: isLoading });
  };

  finishModule = async (moduleId, moduleName) => {
    this.setState({ finishLoading: true });

    try {
      console.log('finishModule state.currentSectionNumber', this.state.currentSectionNumber + 1);
      await this.props.firebase
        .updateUserLessonProgress(this.context.uid, moduleId)
        .update({
          progress: this.state.currentSectionNumber + 1,
          lessonComplete: true,
          completionDate: Date.now(),
          name: moduleName,
        });
      sendOneEmail(
        this.context.email,
        completedModule().subject,
        completedModule(moduleName).message,
        this.context.username || this.context.displayName
      );
      this.toggleFinishLoading(false);
      toast("Successfully submitted feedback!");
      this.props.history.push("/home");
    } catch (error) {
      // this.props.history.push("/home");
      toast("Error submitting lesson");

      this.toggleFinishLoading(false);
      this.setState({ finishLoading: false });

      console.log("ViewLessonPage 959 | ", "ERROR", error);
    }
  };

  switchUp = (currentIndex) => {
    const newSectionsOrder = { ...this.state.sections };
    if (currentIndex === 0) return;
    newSectionsOrder[currentIndex].index = currentIndex - 1;
    newSectionsOrder[currentIndex - 1].index = currentIndex;
    this.setState({
      sections: Object.values(newSectionsOrder),
      switched: true,
    });
  };

  switchDown = (currentIndex) => {
    if (currentIndex === this.state.sections.length - 1) {
      return;
    }
    const newSectionsOrder = { ...this.state.sections };
    newSectionsOrder[currentIndex].index = currentIndex + 1;
    newSectionsOrder[currentIndex + 1].index = currentIndex;
    this.setState({
      sections: Object.values(newSectionsOrder),
      switched: true,
    });
  };

  addBelow = (currentIndex) => {
    const newSectionsOrder = { ...this.state.sections };
    const newSectionsOrderToPush = Object.values(newSectionsOrder);
    newSectionsOrderToPush.splice(currentIndex + 1, 0, {
      name: `New Content ${currentIndex + 2}`,
      index: currentIndex + 1,
    });
    this.setState(
      {
        sections: newSectionsOrderToPush,
        switched: true,
        newSectionAnimationIndex: currentIndex + 1,
      },
      () => {
        this.updateOrder();
      }
    );
  };

  updateOrder = () => {
    const { lessonId } = this.props.match.params;
    const { sections } = this.state;
    this.setState({ updatingOrder: true });
    this.props.firebase
      .updateOrder(lessonId)
      .update({
        sections,
      })
      .then(() => {
        this.setState({ updatingOrder: false });
        toast.success("Order Updated!");
      })
      .catch((error) =>
        console.log("ViewLessonPage.js 435 | error updating order", error)
      );
  };

  currentLessonComplete = () => {
    const { lessonId } = this.props.match.params;

    const complete =
      this.context.lessons &&
        this.context.lessons[lessonId]?.progress ===
        this.context.lessons[lessonId]?.sectionLength
        ? true
        : false;

    return this.context.lessons &&
      this.context.lessons[lessonId] &&
      this.context.lessons[lessonId].lessonComplete &&
      complete
      ? true
      : false;
  };

  renderEditOrViewSection = (type, sections) => {
    const { mode, currentSection, currentSectionNumber, currentSectionEdit } =
      this.state;

    console.log(
      "render edit or view section",
      sections[currentSectionNumber],
      sections,
      currentSectionNumber
    );
    switch (mode) {
      case "edit":
        return (
          <ReactCSSTransitionGroup
            transitionName="example"
            transitionEnterTimeout={1000}
            transitionLeaveTimeout={300}
          >
            <EditSectionForm
              editSection={this.editSection}
              onSectionChange={this.onSectionChange}
              onSectionSubmit={this.onSectionSubmit}
              currentSection={sections[currentSectionNumber]}
              sections={sections}
              currentSectionEdit={currentSectionEdit}
              currentSectionNumber={currentSectionNumber}
              addText={this.addSectionContent}
              removeContentAtIndex={this.removeSectionContentItem}
              cancelEdit={this.cancelEdit}
              addSlide={this.addSlide}
              type={this.state.type}
              removeSlide={this.removeSlide}
              _addSituation={this._addSituation}
              _removeSituation={this._removeSituation}
              _addActionResponseDanger={this._addActionResponseDanger}
              _removeActionResponseDanger={this._removeActionResponseDanger}
              _moveSectionContentDown={this._moveSectionContentDown}
              _moveSectionContentUp={this._moveSectionContentUp}
              copiedTreeBranch={this.state.copiedTreeBranch}
              orderNumber={this.state.module.orderNumber}
            />
          </ReactCSSTransitionGroup>
        );
      case "view":
        return (
          <CSSTransition in={true} timeout={10000} classNames="my-node">
            <ViewSection
              currentSection={
                sections ? sections[currentSectionNumber] : currentSection
              }
              sectionNumber={currentSectionNumber}
              sectionLength={sections ? sections.length : 0}
              prevSection={this.prevSection}
              nextSection={this.nextSection}
              loadedSections={this.state.loadedSections}
              type={this.state.type}
              orderNumber={this.state.module.orderNumber}
              progress={this.state.progress}
              getCurrentSectionProgress={this.getCurrentUserSectionProgress}
              name={this.state.name}
              lessonComplete={this.currentLessonComplete}
              toggleEdit={this.toggleEditMode}
              isAdmin={this.context.isAdmin}
              finishModule={this.finishModule}
              toggleFinishLoading={this.toggleFinishLoading}
              finishLoading={this.state.finishLoading}
              videosDone={this.state.videosDone}
            />
          </CSSTransition>
        );

      default:
        return null;
    }
  };

  handleDragDropReorder = (result) => {
    const newSectionsOrder = [...this.state.sections];

    // when you drop, all items after move by 1 index forward, all items before stay the same

    console.log("ViewLessonPage 1178 | result destination", result.destination);
    let sourceIndex = result?.source?.index;
    let destinationIndex = result?.destination?.index;
    if (destinationIndex === undefined) return;
    console.log(
      "ViewLessonPage 1180 | sourceindex, destinationindex",
      sourceIndex,
      destinationIndex
    );

    let newIndex = newSectionsOrder[destinationIndex].index;

    newSectionsOrder[destinationIndex].index =
      newSectionsOrder[sourceIndex].index;
    newSectionsOrder[sourceIndex].index = newIndex;

    // this.setState({ sections: newSectionsOrder });
    console.log("ViewLessonPage 1219 | end __________________________________");
  };

  componentWillUnmount() {
    // Save the final time spent on the current section
    this.updateUserLessonProgress('unmounted');
  }

  render() {
    const { lessonId } = this.props.match.params;
    const { type } = this.state.module[lessonId]
      ? this.state.module[lessonId]
      : {};

    const {
      userMode,
      currentSection,
      sections,
      currentSectionNumber,
      viewMode,
    } = this.state;

    return (
      <div className="container-fluid lesson-container">
        {this.context.isAdmin ||
          this.context.isViewer ||
          this.context.isMentor ||
          this.currentLessonComplete() ? (
          <>
            <button
              className="toggle-user-admin-view"
              onClick={() => {
                this.setState({
                  viewMode: viewMode === "user" ? "admin" : "user",
                });
              }}
            >
              Switch to {viewMode === "user" ? "Navigation" : "User"} View
            </button>
          </>
        ) : null}

        {this.context &&
          (this.context.isAdmin ||
            this.context.isViewer ||
            this.context.isMentor ||
            this.currentLessonComplete()) &&
          viewMode === "admin" ? (
          <div className="navigation-container">
            <AdminOptions
              userMode={userMode}
              toggleAdminAndUserViews={this.toggleAdminAndUserViews}
              toggleAdd={this.toggleAdd}
              role={this.context.roles[ROLES.ADMIN]}
            />
            <SectionsNavBar
              lessonName={this.state.name}
              sections={this.state.sections}
              userMode={userMode}
              remove={this.removeSection}
              currentSection={
                sections ? sections[currentSectionNumber] : currentSection
              }
              toggleView={this.toggleUserViewMode}
              currentSectionNumber={this.state.currentSectionNumber}
              toggleEdit={this.toggleEditMode}
              addSection={this.addSection}
              onChange={this.onChange}
              switchUp={this.switchUp}
              switchDown={this.switchDown}
              type={type}
              addBelow={this.addBelow}
              handleDragDropReorder={this.handleDragDropReorder}
              newSectionAnimationIndex={this.state.newSectionAnimationIndex}
            />

            <UpdateSectionsOrderButton
              updateOrder={this.updateOrder}
              isVisible={userMode === "ADMIN"}
              updating={this.state.updatingOrder}
            />
          </div>
        ) : null}

        <div
          className={
            this.context && this.context.isAdmin && viewMode === "admin"
              ? "display-lesson-container-admin"
              : "display-lesson-container-user"
          }
        >
          {this.renderEditOrViewSection(type, this.state.sections)}
        </div>
      </div>
    );
  }
}

const condition = (authUser) => authUser;

const mapStateToProps = (state) => {
  const { events } = state;
  return { events };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLessonSections: (firebase, lessonId) =>
      dispatch(getSectionsByLessonId(firebase, lessonId)),
  };
};

const compare = (a, b) => {
  if (a.index > b.index) return 1;
  if (a.index < b.index) return -1;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withAuthorization(condition)
)(ViewLessonPage);
