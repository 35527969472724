import React, { useState, useMemo, useContext } from "react";
import { useTable } from "react-table";
import { Button } from "antd";
import EnhancedModal from "../../../../../../../Modal";
import { withFirebase } from "../../../../../../../Firebase";
import CustomButton from "src/components/shared/Button";
import { AuthUserContext } from "src/components/Session";

const CopyUserTool = ({ userId, firebase }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useContext(AuthUserContext);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isConfirmationStep, setIsConfirmationStep] = useState(false);

  // Filter users based on search term
  const filteredUsers = users.filter(user => {
    if (!user?.email) return false;
    return user.email.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOpenModal = async () => {
    setIsModalOpen(true);
    // Fetch users when modal opens
    try {
      console.log("fetching users");
      const usersRef = firebase.users();
      const snap = await usersRef.once("value");
      const rawData = snap.val();
      console.log("rawData", rawData);

      if (rawData) {
        const usersList = Object.keys(rawData)
          .filter(key => key !== userId) // Exclude current user
          .map((key) => ({
            ...rawData[key],
            uid: key,
          }));
        setUsers(usersList);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleUserSelect = (selectedUser) => {
    setSelectedUser(selectedUser);
    console.log("Selected user:", selectedUser);
    console.log("regular user", user);
    setIsConfirmationStep(true);
  };

  const handleBackToSearch = () => {
    setSelectedUser(null);
    setIsConfirmationStep(false);
  };

  const handleConfirmCopy = async () => {
    // Add your copy logic here
    console.log("Copying data from user:", selectedUser);

    // Check if user exists before destructuring
    if (!user) {
      console.error('User object is undefined');
      return;
    }

    // Safely destructure with default empty object if properties don't exist
    const { uniqueId, uid, displayName, userName, email, refreshToken, expoPushToken, ...userPropertiesToCopy } = {
      uniqueId: undefined,
      uid: undefined,
      displayName: undefined,
      userName: undefined,
      email: undefined,
      refreshToken: undefined,
      expoPushToken: undefined,
      ...selectedUser
    };
    console.log("userPropertiesToCopy", userPropertiesToCopy);

    await firebase.user(user.uid).update({
      ...userPropertiesToCopy
    });

    setIsModalOpen(false);
    setIsConfirmationStep(false);
    setSelectedUser(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "displayName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <Button
            type="primary"
            onClick={() => handleUserSelect(row.original)}
          >
            Select
          </Button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: filteredUsers });

  return (
    <>
      <div
        onClick={handleOpenModal}
        style={{ cursor: 'pointer', color: 'blue' }}
      >
        Copy User
      </div>

      {isModalOpen && (
        <EnhancedModal
          title="Select User to Copy"
          footer={null}
          width={800}
          onCancel={() => {
            setIsModalOpen(false);
            setIsConfirmationStep(false);
            setSelectedUser(null);
          }}
          afterClose={() => {
            setIsModalOpen(false);
            setIsConfirmationStep(false);
            setSelectedUser(null);
          }}
          modalFunction={isConfirmationStep ? handleConfirmCopy : () => { }}
          isAdmin={true}
          okText={isConfirmationStep ? "Confirm Copy" : "Cancel"}
        >
          <div style={{ maxWidth: '700px', margin: '0 auto' }}>
            {!isConfirmationStep ? (
              // Search and table view
              <div>
                <input
                  type="text"
                  placeholder="Search by email..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    marginBottom: '16px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px'
                  }}
                />

                <div style={{ overflowX: 'auto' }}>
                  <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th
                              {...column.getHeaderProps()}
                              style={{
                                padding: '12px',
                                borderBottom: '2px solid #d9d9d9',
                                textAlign: 'left'
                              }}
                            >
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                              <td
                                {...cell.getCellProps()}
                                style={{
                                  padding: '12px',
                                  borderBottom: '1px solid #d9d9d9'
                                }}
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              // Confirmation view
              <div style={{ textAlign: 'center' }}>
                <div style={{ position: 'relative', marginBottom: '24px' }}>
                  <h3>Selected User</h3>
                  <div
                    onClick={handleBackToSearch}
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      cursor: 'pointer',
                      fontSize: '20px',
                      color: '#999'
                    }}
                  >
                    ×
                  </div>
                </div>

                <div style={{
                  padding: '20px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  marginBottom: '24px'
                }}>
                  <p><strong>Name:</strong> {selectedUser?.displayName}</p>
                  <p><strong>Email:</strong> {selectedUser?.email}</p>
                </div>

                <div style={{
                  backgroundColor: '#fffbe6',
                  padding: '16px',
                  borderRadius: '4px',
                  border: '1px solid #ffe58f',
                  marginBottom: '16px'
                }}>
                  <p>Are you sure you want to copy data from this user?</p>
                  <p style={{ marginBottom: 0 }}>This action will copy all relevant data to your account.</p>
                </div>
                <CustomButton onClick={handleConfirmCopy} title="Confirm Copy" type="primary" />
              </div>
            )}
          </div>
        </EnhancedModal>
      )}
    </>
  );
};

export default withFirebase(CopyUserTool);
