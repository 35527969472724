import React, { useEffect } from "react";

import { Spinner } from "src/components/Spinner";
import FinalAssessment from "./components/FinalAssessment";
import FinishedCourse from "./components/FinishedCourse";
import InitialAssessments from "./components/InitialAssessments";
import Modules from "./components/Modules";

import "./index.css";
import EQAssessmentCard from "./components/InitialAssessments/EQAssessmentCard";
import AssessmentLinkCard from "./components/AssessmentLinkCard";

import AndroidQR from "src/android-qr.jpeg";
import IosQR from "src/ios-qr.jpeg";
import {
  initialLSIShown,
  checkAllModulesComplete,
  finalLSIActive,
  initialEqShown,
  exitEqShown,
  finalLSIAssessmentShown,
  finishedInitialAssessments,
  finalAssessmentShown,
  finalAssessmentComplete,
} from "./unlockUtils";
import MyLoader from "src/utils/placeholders";
import { DownloadApp } from "./components/DownloadApp";

export const CourseContent = ({
  firebase,
  authUser,
  getClassroomData,
  classData,
  classDataLoading,
  modules,
  handleModuleStart,
}) => {
  useEffect(() => {}, [
    authUser?.classUID,
    authUser?.lessons,
    authUser?.allModulesComplete,
  ]);

  if (classDataLoading && classData === null) {
    return (
      <div className="coursecontent__container">
        <MyLoader />
      </div>
    );
  }

  return (
    <div className="coursecontent__container">
      <InitialAssessments
        getClassData={getClassroomData}
        authUser={authUser}
        classData={classData}
        finishedInitialAssessments={finishedInitialAssessments(
          authUser,
          classData
        )}
      />

      <div className="preassessmentlinks__container">
        <AssessmentLinkCard
          title="LSI Assessment"
          description="Comprehensive assessment to help you achieve your goals and improve the quality of your life."
          shown={initialLSIShown(authUser, classData)}
          active={true}
          destinationRoute="/lsiassessment"
          timeToComplete="35min"
        />
        <AssessmentLinkCard
          title="EQ Assessment"
          description="Learn about your emotional intelligence with our comprehensive Ghyst EI assessment."
          shown={initialEqShown(authUser, classData)}
          active={!initialLSIShown(authUser, classData)}
          destinationRoute="/eqassessment"
          timeToComplete="15min"
        />
      </div>

      <Modules
        firebase={firebase}
        authUser={authUser}
        finalAssessment={finalAssessmentShown(authUser, classData)}
        finishedInitialAssessments={finishedInitialAssessments(
          authUser,
          classData
        )}
        classData={classData}
        modules={modules}
        handleModuleStart={handleModuleStart}
      />

      {/* {authUser?.classId &&
        authUser?.eqTestComplete &&
        authUser?.allModulesComplete && (
          <EQAssessmentCard
            available={classData?.settings?.eqAvailable ? true : false}
            enabled={false}
            done={false}
            isAdmin={authUser?.isAdmin}
            isMentor={authUser?.isMentor}
          />
        )} */}

      {/* <FinalAssessment
        finalAssessment={finalAssessmentShown(authUser, classData)}
        finalAssessmentDone={finalAssessmentComplete(authUser, classData)}
        authUser={authUser}
        enabled={checkAllModulesComplete(authUser, classData)}
      /> */}
      <div className="preassessmentlinks__container">
        <AssessmentLinkCard
          title="EQ Exit Assessment"
          description="Learn about your emotional intelligence with our comprehensive Ghyst EI assessment."
          shown={exitEqShown(authUser, classData)}
          active={authUser?.allModulesComplete ? true : false}
          destinationRoute="/eqassessment"
          timeToComplete="15min"
        />
        <AssessmentLinkCard
          title="LSI Final Assessment"
          description="Comprehensive assessment to help you achieve your goals and improve the quality of your life."
          shown={exitEqShown(authUser, classData)}
          active={authUser?.allModulesComplete ? true : false}
          destinationRoute="/lsiassessment"
          timeToComplete="30min"
        />
      </div>
      <DownloadApp />
      {/* <FinishedCourse
        isAdmin={authUser?.isAdmin}
        allModulesComplete={authUser?.allModulesComplete}
        finalAssessmentComplete={finalAssessmentComplete(authUser, classData)}
        classData={classData}
      /> */}
    </div>
  );
};

export default CourseContent;
