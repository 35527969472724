// This component show the individual Section of a Lesson
// You will see the Quizzes, Videos, Images and Text created by an Admin.
// This also handles all the CRUD operations of a, individual Section.

import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { withAuthorization, AuthUserContext } from "../Session";
import "./index.css";

import {
  quizSuccessNotification,
  quizFailedNotification,
  videoFailedWarning,
} from "../../utils/notifications";

import { compose } from "recompose";

import ProgressBar from "../ProgressBar";

// Section Components
import { Image, Video, Text, Quiz, UserInput } from "./sectionComponents";
import { DragDrop } from "./sectionComponents/DragDrop";
import ChatGame from "./sectionComponents/ChatGame";
import Likert from "./sectionComponents/Likert";
import Carousel from "./sectionComponents/Carousel";

// Snowball Games
import SheetsContainer from "./sectionComponents/Snowball/Sheets/SheetsContainer/SheetsContainer";
import InvestingContainer from "./sectionComponents/Snowball/Investing/InvestingContainer/InvestingContainer";
import DebtContainer from "./sectionComponents/Snowball/Debt/DebtContainer/DebtContainer";
import SpreadsheetsTutorialContainer from "./sectionComponents/SpreadsheetsTutorial";

import { NextButton } from "./sectionComponents/NextButton";

import DecisionTreeGame from "./sectionComponents/DecisionTreeGame";
import FileDownload from "./sectionComponents/FileDownload";
import Table from "./sectionComponents/Table";
import MultiDragDrop from "./sectionComponents/MultiDragDrop";
import { FinishModuleButton } from "./sectionComponents/FinishModuleButton/FinishModuleButton";
import IssueFeedbackModal from "./FeedbackModal";
import GEDPlatform from "./sectionComponents/GEDPlatform";
import Button from "../shared/Button";
import FeedbackModal from "../FeedbackModal";
import { connect } from "react-redux";
import { useId } from "react";
import { TextSlateView } from "./sectionComponents/TextSlate";
import BudgetingGame from "./sectionComponents/Snowball/BudgetingGame";
import CreditSimulator from "./sectionComponents/Snowball/CreditSimulator";
import NewFinanceLesson from "./sectionComponents/Snowball/NewFinanceLesson";
const SNOWBALL_API_URL = "https://server-snowball.herokuapp.com";

export class ViewSection extends Component {
  static contextType = AuthUserContext;

  state = {
    quizzesDone: false,
    likertDone: false,
    dragAndDropDone: false,

    // User Input
    userInputSaved: false,
    userInputs: [],

    correctQuizAnswerCount: 0,
    numberOfQuestionsAnswered: 0,

    //  Drag&Drop
    dragAndDropComplete: false,
    completedDragAndDrops: 0,
    dragDropCompleteCount: 0,

    categories: {},
    animate: false,
    timesTried: 0,
    token: null,
    likert: null,

    // Videos
    videosDone: false,
    videosDoneCount: 0,

    // ChatGame
    chatGameWon: false,
    treeWon: false,

    // Snowball
    snowballIntroDone: false,
    snowballBudgetingDone: false,

    // Quest API
    questSubmitted: false,

    feedbackModalVisible: false,
    loadingNextButton: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.correctQuizAnswerCount !== nextState.correctQuizAnswerCount ||
      this.state.numberOfQuestionsAnswered !==
        nextState.numberOfQuestionsAnswered ||
      this.state.quizCount === 0 ||
      this.state.dragDropCompleteCount !== nextState.dragDropCompleteCount
    ) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const sessionToken = localStorage.getItem("token");

    this.setState({ token: sessionToken });
    this.videoWarning();
  }

  sectionComponentTypeCount = (type) => {
    const { content } = this.props.currentSection
      ? this.props.currentSection
      : {
          name: "No sections yet",
          content: null,
        };
    const componentTypeCount = content
      ? content.filter((section) => section.type === type).length
      : null;

    return componentTypeCount;
  };

  videoWarning = () => {
    const videoCount = this.sectionComponentTypeCount("video");

    if (videoCount) {
      videoFailedWarning();
    }
  };

  onSubmitSectionToFirebase = (e) => {
    e.preventDefault();
    const { lessonId } = this.props.match.params;
    const { uid } = this.props.currentSection;

    this.props.firebase.updateSectionContent(lessonId, uid).update({
      ...this.props.currentSection,
    });
  };

  onLikertChange = (index, value, question, category) => {
    console.log("ViewSection 117 | on likert change ", category);
    let likertCopy = {
      ...this.state.likert,
    };

    likertCopy = {
      ...likertCopy,
      [index]: {
        value,
        question,
        category: category ? category : "",
      },
    };

    this.setState(
      {
        likert: likertCopy,
      },
      () => {
        console.log("ViewSection 174 | likert", this.state.likert);
        this.checkIfAllLikertsAnswered();
      }
    );
  };

  checkIfAllLikertsAnswered = () => {
    const likertCountInContent = this.sectionComponentTypeCount("likert");

    const likertCountInState = Object.values(this.state.likert).length;

    if (likertCountInContent === likertCountInState) {
      console.log("ViewSection 181 | likerts done");
      this.setState({ likertDone: true });
    }
  };

  // QUIZ HANDLER
  checkQuizAnswer = (option, answer, category) => {
    const categories = this.handleQuizCategories(option, answer, category);
    const isPreModuleQuizSection =
      this.props.currentSection &&
      this.props.currentSection.name === "Pre-Module Quizzes";
    if (option === answer) {
      this.addCorrectQuizAnswer();
    }

    // Every time you answer, currentNumberOfQuestionsAnswered is increased by one.
    this.setState(
      {
        numberOfQuestionsAnswered: this.state.numberOfQuestionsAnswered + 1,
      },
      () => {
        if (this.quizCompletelyAnswered()) {
          if (isPreModuleQuizSection || this.quizPassed()) {
            quizSuccessNotification(isPreModuleQuizSection);
            this.setState({ quizzesDone: true, categories });
          } else {
            // You failed a normal RED quiz and will move back
            this.handleQuizFailed();
            this.setState({ quizzesDone: false });
          }
        }
      }
    );
  };

  addCorrectQuizAnswer = () => {
    this.setState({
      correctQuizAnswerCount: this.state.correctQuizAnswerCount + 1,
    });
  };

  handleQuizCategories = (option, answer, category) => {
    const { categories } = {
      ...this.state,
    };

    if (categories[category]) {
      // check if categories exist
      categories[category] = {
        ...categories[category],
        total: categories[category]["total"] + 1,
      };
      if (option === answer) {
        categories[category] = {
          ...categories[category],
          correct: categories[category]["correct"] + 1,
        };
      }
    } else {
      if (typeof category !== "undefined") {
        categories[category] = {
          total: 1,
          correct: 0,
        };
        if (option === answer) {
          categories[category] = {
            ...categories[category],
            correct: 1,
          };
        }
      }
    }

    return categories;
  };

  quizCompletelyAnswered = () => {
    const sectionQuizCount = this.sectionComponentTypeCount("quiz");
    return this.state.numberOfQuestionsAnswered === sectionQuizCount;
  };

  quizPassed = () => {
    return (
      this.state.correctQuizAnswerCount /
        this.state.numberOfQuestionsAnswered >=
      0.7
    );
  };

  getQuizCount = () => {
    const quizCount = this.sectionComponentTypeCount("quiz");
    return quizCount;
  };

  getChatGameCount = () => {
    const chatCount = this.sectionComponentTypeCount("chatGame");
    return chatCount;
  };

  setChatGameDone = () => {
    this.setState({
      chatGameWon: true,
    });
  };

  isChatDone = () => {
    if (this.state.chatGameWon) return true;
    if (this.getChatGameCount() === 0) return true;
    return false;
  };

  getLikertCount = () => {
    if (this.props.currentSection) {
      const likertCount = this.sectionComponentTypeCount("likert");
      return likertCount;
    }
    return false;
  };

  handleQuizFailed = () => {
    const { correctQuizAnswerCount } = this.state;
    console.log(
      "ViewSection 332 | handling quiz failed",
      this.state.timesTried
    );

    const quizCount = this.getQuizCount();
    quizFailedNotification(correctQuizAnswerCount, quizCount);
    window.scrollTo(0, 0);
    this.setState(
      {
        animate: false,
        quizzesDone: false,
        correctQuizAnswerCount: 0,
        numberOfQuestionsAnswered: 0,
        categories: {},
        timesTried: this.state.timesTried + 1,
        videosDone: true,
      },
      () => {
        setTimeout(() => {
          console.log("ViewSection 365 | set quizzes UNDONE");
          this.props.prevSection();
        }, 2000);
      }
    );
  };

  handleNextButtonPressed = async () => {
    console.log("ViewSection 350 | handling button pressed");
    const quizCount = this.sectionComponentTypeCount("quiz");
    const userInputCount = this.sectionComponentTypeCount("user-input");
    const likertCount = this.sectionComponentTypeCount("likert");

    if (quizCount) {
      console.log("ViewSection 343 | quiz count", quizCount);
      await this.submitSectionQuizDataToFirebase();
    }

    if (userInputCount) {
      console.log("ViewSection 348 | user input count", userInputCount);
      await this.submitUserInput();
    }

    if (likertCount) {
      console.log("ViewSection 365 | likert exists, submit to database");
      await this.submitLikertDataToFirebase();
    }

    this.setState(
      {
        animate: false,
        quizzesDone: false,
        correctQuizAnswerCount: 0,
        numberOfQuestionsAnswered: 0,
        categories: {},
        videosDone: false,
        videosDoneCount: 0,
        dragAndDropComplete: false,
        dragDropCount: 0,
        dragDropCompleteCount: 0,
        likert: null,
        likertDone: false,
        loadingNextButton: false,
      },
      () => {
        this.props.nextSection(this.context.uid);
      }
    );
  };

  submitSectionQuizDataToFirebase = async () => {
    const { uid } = this.context;
    const { uid: sectionId } = this.props.currentSection;
    const { lessonId } = this.props.match.params;
    const { categories } = this.state;

    const quizCount = this.sectionComponentTypeCount("quiz");

    let quizPerformance = this.state.correctQuizAnswerCount / quizCount;

    try {
      const updateData = {
        name: this.props.currentSection.name,
        quizPerformance: quizPerformance,
        timesTried: this.state.timesTried + 1,
        categories,
      };

      const { firebase, name } = this.props;

      if (this.props.currentSection.name === "Pre-Module Quizzes") {
        await firebase
          .userPreModuleQuizData(uid)
          .set(updateData)
          .then(() => {
            this.setState({ timesTried: 0 });
          })
          .catch((error) => {
            console.log(
              "ViewSection 422 | error updating quiz data",
              "ERROR",
              error
            );
          });
      } else {
        await firebase
          .updateQuizData(uid, lessonId, sectionId)
          .update(updateData)
          .then(() => {
            this.setState({ timesTried: 0 });
          })
          .catch((error) => {
            console.log(
              "ViewSection 422 | error updating quiz data",
              "ERROR",
              error
            );
          });

        await firebase.updateQuizDataName(uid, lessonId).update({ name });

        await this.submitQuizAnalytics(updateData);
      }

      await firebase
        .updateUserSectionProgress(uid, lessonId, sectionId)
        .update(updateData);
    } catch (error) {
      console.log("ViewSection 439 | error updating quiz data", error);
    }
  };

  submitQuizAnalytics = async (updateData) => {
    // get previous data
    // -> if there's NO previous data, create new data
    // -> if there IS previous data, add the new quiz and submit a transaction with new data
    const { firebase } = this.props;
    const { lessonId } = this.props.match.params;
    const { uid: sectionId } = this.props.currentSection;

    const quizRef = firebase.quizAnalytics().doc(`${lessonId}_${sectionId}`);

    try {
      return firebase.fs.runTransaction(async (t) => {
        const doc = await t.get(quizRef);
        if (!doc.exists) {
          // NEW DOC
          quizRef.set({
            quizName: updateData.name,
            timesTaken: 1,
            averageScore: updateData.quizPerformance,
            timesTried: 1,
            averageTimesTried: updateData.timesTried,
          });
        } else {
          // UPDATE PREVIOUSLY EXISTING DOC
          const newTimesTaken = doc.data().timesTaken + 1;
          const newAverageScore =
            (doc.data().averageScore * doc.data().timesTaken +
              updateData.quizPerformance) /
            (doc.data().timesTaken + 1);

          const newAverageTimesTried =
            (doc.data().averageTimesTried * doc.data().timesTried +
              updateData.timesTried) /
            (doc.data().timesTaken + 1);

          const newTotalTimesTried = doc.data().timesTried + 1;

          t.update(quizRef, {
            averageScore: newAverageScore,
            timesTaken: newTimesTaken,

            averageTimesTried: newAverageTimesTried,
            timesTried: newTotalTimesTried,
          });
        }
      });
    } catch (error) {
      console.log("ViewSection 476 | error submitting transaction", error);
    }
  };

  submitLikertDataToFirebase = async () => {
    //revise this function TODO
    const { uid } = this.context;
    const { lessonId } = this.props.match.params;
    const LIKERT_DB_DOC_NAME = `${uid}_${lessonId}`;
    const { name } = this.props;

    try {
      const likertRequest = await this.props.firebase
        .getLikertData()
        .child(LIKERT_DB_DOC_NAME)
        .once("value");
      if (likertRequest.val()) {
        console.log("ViewSection 468 | sent data to post test");
        await this.props.firebase
          .updateLikertData()
          .child(LIKERT_DB_DOC_NAME)
          .update({
            postTest: this.state.likert,
          });
      } else {
        console.log(
          "ViewSection 526 | no likert data, send pre-test",
          this.state.likert
        );
        const preTest = await this.props.firebase
          .setLikertData()
          .child(LIKERT_DB_DOC_NAME)
          .set({
            name,
            uid,
            preTest: this.state.likert,
          });
        console.log("ViewSection 537 | pre test sent", preTest);
      }
    } catch (error) {
      console.log("ViewSection 529 | error getting likert data", error);
    }
  };

  /// userInput

  sentenceToCamelCase = (sentence) => {
    // Remove non-word characters (like "?")
    let cleanedSentence = sentence.replace(/\W/g, " ");

    // Split the sentence into words
    let words = cleanedSentence.split(" ");

    // Map through words array and capitalize each word after the first
    let camelCasedWords = words.map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words back into a single string with no spaces
    let camelCasedSentence = camelCasedWords.join("");

    return camelCasedSentence;
  };

  setUserInput = (data) => {
    console.log("data", data);
    this.setState({ userInputs: data, userInputSaved: true });
  };

  submitUserInput = async () => {
    //revise this function TODO
    const { uid } = this.context;
    const { lessonId } = this.props.match.params;
    const { index } = this.props.currentSection;
    const camelInput = this.sentenceToCamelCase(this.state.userInputs.prompt);

    try {
      await this.props.firebase
        .userLessonInputs(uid, lessonId, camelInput)
        .update(this.state.userInputs);
      this.setState({ userInputs: [], userInputSaved: false });
    } catch (error) {
      console.log("ViewSection 529 | error getting likert data", error);
    }
  };

  sectionQuizCompleteAndPassed = () => {
    console.log("sectionQuizCompleteAndPassed");
    const quizCount = this.sectionComponentTypeCount("quiz");

    let quizPerformance = this.state.correctQuizAnswerCount / quizCount;

    if (
      this.state.quizzesDone &&
      (this.props.currentSection.name === "Pre-Module Quizzes" ||
        quizPerformance >= 0.7)
    )
      return true;

    return false;
  };

  noQuizzes = () => {
    const quizCount = this.sectionComponentTypeCount("quiz");
    if (quizCount === 0) return true;
    return false;
  };

  noUserInput = () => {
    const userInputCount = this.sectionComponentTypeCount("user-input");
    if (userInputCount === 0) return true;
    return false;
  };

  videosDone = () => {
    const videoCount = this.sectionComponentTypeCount("video");
    if (this.state.videosDone || videoCount === 0) {
      return true;
    } else {
      return false;
    }
  };

  setVideosDone = (overrideAll) => {
    const videoCount = this.sectionComponentTypeCount("video");
    if (videoCount === 0) {
      return;
    } else if (!this.state.videosDone) {
      if (overrideAll) {
        this.setState({ videosDone: true });
      } else {
        this.setState({
          videosDoneCount: this.state.videosDoneCount + 1,
          videosDone:
            this.state.videosDoneCount + 1 === videoCount ? true : false,
        });
      }
    }
  };

  checkDragDropComplete = (current, total) => {
    const dragDropCount = this.sectionComponentTypeCount("drag-drop");

    if (current === total) {
      console.log("ViewSection 690 | single drag drop complete");
      let stateCopy = { ...this.state };
      stateCopy.dragDropCompleteCount++;

      if (stateCopy.dragDropCompleteCount === dragDropCount) {
        stateCopy.dragAndDropComplete = true;
        this.setState({ dragAndDropComplete: stateCopy.dragAndDropComplete });
      } else {
        this.setState(stateCopy);
      }
    }
  };

  isDragAndDropComplete = () => {
    const dragDropCount = this.sectionComponentTypeCount("drag-drop");
    if (this.state.dragAndDropComplete || dragDropCount === 0) {
      return true;
    } else {
      return false;
    }
  };

  snowballIntroDone = () => {
    const snowballCount = this.sectionComponentTypeCount("snowball-intro");
    if (snowballCount === 0 || this.state.snowballIntroDone === true) {
      return true;
    } else {
      return false;
    }
  };

  setSnowballIntroDone = () => {
    this.setState({ snowballIntroDone: true });
  };

  setSnowballBudgetingDone = () => {
    console.log("ViewSection 696 | set budgeting done");
    this.setState({ snowballBudgetingDone: true });
  };

  resetVideoDone = () => {
    this.setState({ videoDone: false });
  };

  snowballSheetsDone = () => {
    const snowballSheets = this.sectionComponentTypeCount(
      "snowball-spreadsheets"
    );
    if (snowballSheets === 0) {
      return true;
    } else {
      return false;
    }
  };

  snowballBudgeting = () => {
    const budgetingGame = this.sectionComponentTypeCount("budgeting");
    if (budgetingGame === 0) {
      return true;
    } else {
      return false;
    }
  };

  snowballDebt = () => {
    const debtGame = this.sectionComponentTypeCount("debt");
    if (debtGame === 0) {
      return true;
    } else {
      return false;
    }
  };

  decisionTreeWon = () => {
    const decisionTreeContent = this.sectionComponentTypeCount("decisionTree");
    if (decisionTreeContent === 0 || this.state.treeWon) {
      return true;
    } else {
      return false;
    }
  };

  questTestSubmitted = () => {
    const questContent = this.sectionComponentTypeCount("quest-api");
    if (questContent === 0 || this.state.questSubmitted) {
      return true;
    } else {
      return false;
    }
  };

  questTestSubmit = () => {
    this.setState({ questSubmitted: true });
  };

  setTreeWon = () => {
    this.setState({ treeWon: true });
  };

  decisionTreeDone = () => {};

  endOfModule = () => {
    if (this.props.sectionNumber < this.props.sectionLength - 1) {
      return false;
    }
    return true;
  };

  GEDComplete = () => {
    const gedSection = this.sectionComponentTypeCount("ged-platform");
    if (gedSection === 0) {
      return true;
    } else {
      if (this.context.gedComplete) {
        return true;
      }
      return false;
    }
  };

  likertComplete = () => {
    if (this.getLikertCount() > 0) {
      if (this.state.likertDone) {
        return true;
      }
      return false;
    }
    return true;
  };

  sectionComplete = () => {
    return (
      (this.sectionQuizCompleteAndPassed() || this.noQuizzes()) &&
      (this.noUserInput() || this.state.userInputSaved) &&
      this.videosDone() &&
      this.snowballIntroDone() &&
      this.snowballSheetsDone() &&
      this.snowballBudgeting() &&
      this.decisionTreeWon() &&
      this.isDragAndDropComplete() &&
      this.snowballDebt() &&
      !this.endOfModule() &&
      this.questTestSubmitted() &&
      this.GEDComplete() &&
      this.likertComplete()
    );
  };

  renderNextButton = () => {
    if (this.props.lessonComplete() || this.sectionComplete()) {
      return (
        <NextButton
          disabled={this.state.loadingNextButton}
          handleNext={() => {
            this.setState({ loadingNextButton: true });
            this.handleNextButtonPressed();
          }}
        />
      );
    } else {
      return <NextButton disabled={true} />;
    }
  };

  renderBackButton = () => {
    return (
      <Button
        title="Back"
        type="outline"
        onClick={() => {
          this.props.prevSection();
        }}
      />
    );
  };

  toggleFeedbackModal = () => {
    this.setState({ feedbackModalVisible: !this.state.feedbackModalVisible });
  };

  checkLikertDone = () => {
    if (this.getLikertCount() > 0 && !this.state.likertDone) {
      return false;
    } else {
      return true;
    }
  };

  handleFinishModule = async (rating, textFeedback) => {
    const { lessonId } = this.props.match.params;

    const quizCount = this.sectionComponentTypeCount("quiz");

    const likertCount = this.sectionComponentTypeCount("likert");

    if (quizCount) {
      await this.submitSectionQuizDataToFirebase();
    }

    if (likertCount) {
      console.log("ViewSection 811 | module finished, there is a likert scale");
      await this.submitLikertDataToFirebase();
      await this.props.finishModule(lessonId, this.props.name);
    } else {
      console.log("ViewSection 783 | no likert to submit");
      await this.props.finishModule(lessonId, this.props.name);
    }
    console.log("ViewSection 814 | submit feedback to lesson id document");
    if (process.env.NODE_ENV !== "development") {
      this.props.firebase
        .modulesFeedback(lessonId)
        .push({ lessonId, rating, textFeedback, date: Date.now() });
    }
  };

  updateVideoDuration = (duration, index) => {
    const { lessonId } = this.props.match.params;
    const { uid } = this.props.currentSection;

    let currentSectionCopy = { ...this.props.currentSection };

    currentSectionCopy.content[index].duration = duration;

    //add duration on specific section item content
    this.props.firebase
      .updateSectionContent(lessonId, uid)
      .update({
        ...currentSectionCopy,
      })
      .then(() => {
        console.log("ViewSection 767 | updated duration");
      })
      .catch((error) => {
        console.log("ViewSection 769 | ", "ERROR", error);
      });
  };

  containsSnowballContent = (content) => {
    console.log("content", content);
    return content?.some(
      (item) =>
        item.type === "budgetingGame" ||
        item.type === "creditGame" ||
        item.type === "newFinanceSpreadsheets"
    );
  };

  render() {
    console.log("this.props.currentSection", this.props.currentSection);
    const { name, content, creatorName } = this.props.currentSection
      ? this.props.currentSection
      : {
          name: "No sections yet",
          content: null,
        };

    const { type, sectionNumber, sectionLength, loadedSections } = this.props;
    const quizCount = this.sectionComponentTypeCount("quiz");
    const { lessonId } = this.props.match.params;

    let quizPerformance = this.state.correctQuizAnswerCount / quizCount;

    return (
      <div
        className={`current-section-container ${
          this.state.animate ? null : "animate"
        }`}
      >
        <IssueFeedbackModal
          authUser={this.context}
          lessonName={this.props.name}
          lessonId={this.props.match.params.lessonId}
          sectionName={name}
          issuesModalVisible={this.props.issuesModalVisible}
        />
        <FeedbackModal
          formVisible={this.state.feedbackModalVisible}
          handleFinishModule={this.handleFinishModule}
          finishLoading={this.props.finishLoading}
          lessonName={this.props.name}
          lessonId={lessonId}
        />
        <ProgressBar
          height={20}
          backgroundColor="#FFEBEB"
          completeColor="#D94645"
          progress={Math.floor((sectionNumber / sectionLength) * 100)}
        />
        {this.containsSnowballContent(content) ? null : (
          <h3 className="section-title">{name}</h3>
        )}
        {this.props.isAdmin && creatorName && <h6>Creator: {creatorName}</h6>}
        {loadedSections ? (
          <div className="section-content">
            {this.props.isAdmin ? (
              <button
                onClick={() => this.props.toggleEdit(this.props.currentSection)}
                className="contentedit__button btn btn-outline-success"
              >
                Edit
              </button>
            ) : null}

            {content
              ? content.map((item, index) => {
                  console.log("item", item);
                  switch (item.type) {
                    case "text":
                      return (
                        <Text
                          key={index}
                          value={item.value}
                          type={type}
                          quizzesDone={this.state.quizzesDone}
                        />
                      );
                    case "image":
                      return (
                        <Image
                          key={index}
                          item={item}
                          source={item.url}
                          width={item.imageWidth}
                          type={type}
                        />
                      );
                    case "quiz":
                      return (
                        <Quiz
                          key={Math.random() * 1000}
                          type={type}
                          item={item}
                          checkQuizAnswer={this.checkQuizAnswer}
                          quizzesDone={this.state.quizzesDone}
                          quizPerformance={quizPerformance}
                          index={index}
                          lessonComplete={this.props.lessonComplete()}
                          quizCount={this.sectionComponentTypeCount("quiz")}
                        />
                      );
                    case "user-input":
                      return (
                        <UserInput
                          key={index}
                          type={type}
                          item={item}
                          index={index}
                          setUserInput={this.setUserInput}
                        />
                      );
                    case "video":
                      return (
                        <Video
                          key={index}
                          index={index}
                          sectionNumber={sectionNumber}
                          source={item.url}
                          item={item}
                          type={type}
                          videoDone={this.setVideosDone}
                          progress={this.props.progress}
                          lessonId={this.props.match.params.lessonId}
                          getCurrentSectionProgress={
                            this.props.getCurrentSectionProgress
                          }
                          videosDone={this.props.videosDone}
                          updateVideoDuration={this.updateVideoDuration}
                        />
                      );

                    case "text-slate":
                      return (
                        <TextSlateView
                          key={index}
                          textValue={item.textValue}
                          type={type}
                        />
                      );
                    case "chatGame":
                      return (
                        <ChatGame
                          key={Math.random() * 1000}
                          type={type}
                          data={item}
                          setChatGameDone={this.setChatGameDone}
                        />
                      );
                    case "drag-drop":
                      return (
                        <DragDrop
                          key={Math.random() * 1000}
                          data={item}
                          type={type}
                          checkDragDrop={this.checkDragDropComplete}
                          dragAndDropComplete={this.state.dragAndDropComplete}
                          lessonComplete={this.props.lessonComplete()}
                        />
                      );
                    case "likert":
                      return (
                        <Likert
                          key={index}
                          lessonComplete={this.props.lessonComplete()}
                          index={index}
                          item={item}
                          onLikertChange={this.onLikertChange}
                        />
                      );
                    case "carousel":
                      return (
                        <Carousel
                          key={index}
                          index={index}
                          item={item}
                          type={type}
                        />
                      );
                    case "multi-dragdrop":
                      return <MultiDragDrop key={index} />;
                    case "budgeting":
                      return (
                        <SheetsContainer
                          key={index}
                          userId={this.context.uid}
                          token={this.state.token}
                          userData={this.state.userData}
                          toggleNext={this.setSnowballBudgetingDone}
                          handleNext={this.handleNextButtonPressed}
                        />
                      );
                    case "investing":
                      return (
                        <InvestingContainer
                          key={index}
                          token={this.state.token}
                          API_URL={SNOWBALL_API_URL}
                          userData={this.state.userData}
                        />
                      );
                    case "debt":
                      return (
                        <DebtContainer
                          key={index}
                          token={this.state.token}
                          API_URL={SNOWBALL_API_URL}
                          debtData={400}
                          handleNextButtonPressed={this.handleNextButtonPressed}
                        />
                      );
                    case "snowball-spreadsheets":
                      return (
                        <SpreadsheetsTutorialContainer
                          key={index}
                          index={index}
                          token={this.state.token}
                          lessonId={this.props.match.params.lessonId}
                          isAdmin={this.context.isAdmin}
                          sectionId={this.props.currentSection}
                          item={item}
                          handleNext={this.handleNextButtonPressed}
                          title={
                            this.props.currentSection
                              ? this.props.currentSection.name
                              : null
                          }
                          name={
                            this.props.currentSection
                              ? this.props.currentSection.name
                              : null
                          }
                          userData={this.state.userData}
                          userId={this.context.uid}
                          data={item.data}
                          logOut={this.props.firebase.signOut}
                        />
                      );
                    case "decisionTree":
                      return (
                        <DecisionTreeGame
                          key={Math.random() * 1000}
                          index={index}
                          treeData={item}
                          type={type}
                          setTreeWon={this.setTreeWon}
                        />
                      );
                    case "file":
                      return <FileDownload key={index} item={item} />;
                    case "table":
                      return <Table key={index} item={item} />;

                    case "ged-platform":
                      return <GEDPlatform key={index} item={item} />;
                    case "budgetingGame":
                      return (
                        <BudgetingGame
                          key={index}
                          item={item}
                          firebase={this.props.firebase}
                          handleNext={this.handleNextButtonPressed}
                        />
                      );
                    case "newFinanceSpreadsheets":
                      return (
                        <NewFinanceLesson
                          key={index}
                          item={item}
                          firebase={this.props.firebase}
                          handleNext={this.handleNextButtonPressed}
                        />
                      );
                    case "creditGame":
                      return (
                        <CreditSimulator
                          key={index}
                          item={item}
                          firebase={this.props.firebase}
                          handleNext={this.handleNextButtonPressed}
                        />
                      );
                    default:
                      return null;
                  }
                })
              : null}
            {this.props.sectionNumber === this.props.sectionLength - 1 &&
            this.checkLikertDone() ? (
              <div className="buttons-container">
                <Button
                  title="Back"
                  type="outline"
                  onClick={() => {
                    this.setState({ videosDone: true });
                    this.props.prevSection();
                  }}
                />

                <FinishModuleButton
                  finishLoading={this.props.finishLoading}
                  toggleFeedbackModal={this.toggleFeedbackModal}
                />
              </div>
            ) : (
              <>
                {this.containsSnowballContent(content) ? null : (
                  <div className="buttons-container">
                    {this.renderBackButton()}
                    {this.renderNextButton()}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          "Loading"
        )}
      </div>
    );
  }
}

const condition = (authUser) => authUser;

export default compose(withFirebase, withAuthorization(condition))(ViewSection);
