export const eqComplete = (authUser, classData) => {
  return authUser?.eqTestComplete || classData?.settings?.eqAvailable === false;
};

export const lsiDone = (authUser, classData) => {
  return (
    authUser?.lsiDone ||
    classData?.settings?.lsiAvailable === false ||
    typeof authUser?.lsiDone === "undefined"
  );
};

export const initialLSIShown = (authUser, classData) => {
  if (authUser?.lsiInitialAssessmentComplete === true) return false;
  return classData?.settings?.lsiInitialAssessment === true ? true : false;
};

export const finalLSIActive = (authUser, classData) => {
  if (authUser?.eqPostTestComplete) return true;
  const modulesComplete = authUser?.allModulesComplete;
  return modulesComplete;
};

export const checkAllModulesComplete = (authUser, classData) => {
  const modulesAvailable = classData?.settings?.modules || {};
  if (!authUser.lessons) {
    return false;
  }

  if (!classData?.settings?.modules) {
    console.log("CourseContent 118 | no modules");
    return false;
  }

  if (
    Object.values(authUser.lessons).length !==
    Object.values(classData?.settings?.modules).filter(
      (module) => module.available
    ).length
  ) {
    return false;
  }

  // Check if every available module is completed
  return Object.values(modulesAvailable).every((module) => {
    if (module.available) {
      const moduleComplete = authUser?.lessons[module.uid]?.lessonComplete;
      if (!moduleComplete) {
        return false;
      }
      return moduleComplete;
    }
    return true;
  });
};

export const initialEqShown = (authUser, classData) => {
  if (!classData?.settings?.eqInitialAssessment) return false;
  if (authUser?.eqTestComplete) return false;
  return !authUser?.eqTestComplete ||
    classData?.settings?.eqInitialAssessment === true
    ? true
    : false;
};

export const exitEqShown = (authUser, classData) => {
  if (!authUser?.eqTestComplete) return false;
  if (authUser?.eqPostTestComplete) return false;
  if (!classData?.settings?.eqFinalAssessment) return false;

  return true;
};

export const finalLSIAssessmentShown = (authUser, classData) => {
  if (
    authUser?.lsiInitialAssessmentComplete === false ||
    !authUser.lsiInitialAssessmentComplete
  )
    return false;
  if (authUser?.lsiFinalAssessmentComplete) return false;

  if (!classData?.settings?.lsiFinalAssessment) return false;

  return true;
};

export const finishedInitialAssessments = (authUser, classData) =>
  (authUser?.classId &&
    eqComplete(authUser, classData) &&
    lsiDone(authUser, classData)) ||
  authUser?.isAdmin ||
  authUser?.isViewer;

export const finalAssessmentShown = (authUser, classData) => {
  const initialAssessmentsFinished = finishedInitialAssessments(
    authUser,
    classData
  );
  const finalAssessmentAvailable =
    classData?.settings?.finalAssessmentAvailable;

  if (!initialAssessmentsFinished) {
    return false;
  } else {
    return finalAssessmentAvailable ? true : false;
  }
};

export const finalAssessmentComplete = (authUser, classData) => {
  return (
    authUser?.finalAssessmentDone ||
    classData?.settings?.finalAssessmentAvailable === false
  );
};
