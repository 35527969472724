import React, { useMemo, useContext, useState, useEffect, useCallback } from "react";
import { useTable } from "react-table";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import "./index.css";
import {
  AuthUserContext,
  withAuthentication,
  withAuthorization,
} from "../Session";
import { Button } from "antd";
import EnhancedModal from "../Modal";
//import "./styles.css";

const SimpleUsersTable = ({ firebase }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const authUser = useContext(AuthUserContext);
  const [userToDelete, setUserToDelete] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [userAssociatedData, setUserAssociatedData] = useState({});

  console.log('userToDelete', userToDelete);

  // Filter users based on search term
  const filteredUsers = users.length > 0 ? users.filter(user => {
    if (!user?.email) return false;
    return user.email.toLowerCase().includes(searchTerm.toLowerCase());
  }) : [];

  useEffect(() => {
    console.log('useEffect getAllUsers');
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    console.log('getAllUsers - starting');
    try {
      console.log('Attempting to fetch users from Firebase...');
      const usersRef = firebase.users();
      console.log('Users reference:', usersRef);

      usersRef
        .once("value")
        .then((snap) => {
          console.log('Firebase response received');
          const rawData = snap.val();
          console.log('Raw data from Firebase:', rawData);

          if (!rawData) {
            console.log('No data returned from Firebase');
            setUsers([]);
            setLoading(false);
            return;
          }

          const usersList = Object.keys(rawData).map((key) => ({
            ...rawData[key],
            uid: key,
          }));

          console.log('Transformed users list:', usersList);
          setUsers(usersList);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Firebase query error:', error);
          setLoading(false);
        });
    } catch (error) {
      console.error('Overall error in getAllUsers:', error);
      setLoading(false);
    }
  };

  const checkUserData = async (uid) => {
    const dataLocations = {
      'LSI Data': {
        ref: firebase.lsiData(uid),
        type: 'realtime'
      },
      'Quiz Data': {
        ref: firebase.userQuizData(uid),
        type: 'realtime'
      },
      'Final Assessment': {
        ref: firebase.finalAssessmentUserData(uid),
        type: 'realtime'
      },
      'Likert Data': {
        ref: firebase.getUserLikertData(uid),
        type: 'realtime'
      },
      'EQ Data': {
        ref: firebase.eiGhystUserDataPreTest(uid),
        type: 'realtime'
      },
      'Pre-Module Quiz Data': {
        ref: firebase.userPreModuleQuizData(uid),
        type: 'realtime'
      },
      'Pre-LSI Assessment': {
        ref: firebase.preLsiAssessmentResultsNew(uid),
        type: 'firestore'
      },
      'Post-LSI Assessment': {
        ref: firebase.postLsiAssessmentResults(uid),
        type: 'firestore'
      }
    };

    const foundData = {};

    // Check each location for data
    for (const [key, { ref, type }] of Object.entries(dataLocations)) {
      try {
        let exists = false;
        if (type === 'realtime') {
          const snapshot = await ref.once('value');
          exists = snapshot.exists();
        } else if (type === 'firestore') {
          const doc = await ref.get();
          exists = doc.exists;
        }

        if (exists) {
          foundData[key] = true;
        }
      } catch (error) {
        console.error(`Error checking ${key}:`, error);
      }
    }

    return foundData;
  };

  const handleUserSelect = async (user) => {
    const associatedData = await checkUserData(user.uid);
    setUserAssociatedData(associatedData);
    setUserToDelete(user);
  };

  const handleDeleteUser = async () => {
    const firstName = userToDelete.displayName?.split(" ")[0] || "";
    if (!userToDelete?.uid || deleteConfirmation !== firstName) return;

    // Delete all associated data
    const deletePromises = [];

    // Realtime Database deletions (using .remove())
    if (userAssociatedData['LSI Data']) {
      deletePromises.push(firebase.lsiData(userToDelete.uid).remove());
    }
    if (userAssociatedData['Quiz Data']) {
      deletePromises.push(firebase.userQuizData(userToDelete.uid).remove());
    }
    if (userAssociatedData['Final Assessment']) {
      deletePromises.push(firebase.finalAssessmentUserData(userToDelete.uid).remove());
    }
    if (userAssociatedData['Likert Data']) {
      deletePromises.push(firebase.getUserLikertData(userToDelete.uid).remove());
    }
    if (userAssociatedData['EQ Data']) {
      deletePromises.push(firebase.allEQData(userToDelete.uid).remove());
    }
    if (userAssociatedData['Pre-Module Quiz Data']) {
      deletePromises.push(firebase.userPreModuleQuizData(userToDelete.uid).remove());
    }

    // Firestore deletions (using .delete())
    if (userAssociatedData['Pre-LSI Assessment']) {
      deletePromises.push(firebase.preLsiAssessmentResults(userToDelete.uid).delete());
    }
    if (userAssociatedData['Post-LSI Assessment']) {
      deletePromises.push(firebase.postLsiAssessmentResults(userToDelete.uid).delete());
    }

    deletePromises.push(firebase.userFromChatroom(userToDelete?.classUID, userToDelete?.uid).remove());


    // Add the user deletion to the promises (Realtime Database)
    deletePromises.push(firebase.user(userToDelete.uid).remove());

    try {
      await Promise.all(deletePromises);
      await getAllUsers();
      setUserToDelete(null);
      setDeleteConfirmation('');
      setUserAssociatedData({});
    } catch (error) {
      console.error('Error deleting user data:', error);
      // You might want to add error handling UI here
    }
  };

  // Add useEffect to monitor modal state
  useEffect(() => {
    return () => {
      // Cleanup function
      handleModalClose();
    };
  }, []);

  // Make sure handleModalClose is not recreated on every render
  const handleModalClose = useCallback(() => {
    setUserToDelete(null);
    setDeleteConfirmation('');
    setUserAssociatedData({});
  }, []);

  // Add a console log to debug modal state
  console.log('Modal state:', {
    userToDelete,
    deleteConfirmation,
    userAssociatedData
  });

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: (row) => row.displayName?.split(" ")[0] || "",
      },
      {
        Header: "Last Name",
        accessor: (row) => row.displayName?.split(" ").slice(1).join(" ") || "",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <Button
            type="primary"
            danger
            onClick={() => handleUserSelect(row.original)}
          >
            Delete
          </Button>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => filteredUsers, [filteredUsers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div className="simple-table-container">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      <table {...getTableProps()} className="simple-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td key={cell.column.id} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {userToDelete && (
        <EnhancedModal
          title="Delete User"
          width={500}
          modalFunction={handleDeleteUser}
          isAdmin={true}
          disabled={deleteConfirmation !== userToDelete.displayName?.split(" ")[0]}
          onCancel={handleModalClose}
          afterClose={handleModalClose}
          footer={true}
        >
          <p>Are you sure you want to delete user {userToDelete.email}?</p>
          <p>This action cannot be undone.</p>

          {Object.keys(userAssociatedData).length > 0 && (
            <>
              <p>The following associated data will also be deleted:</p>
              <ul>
                {Object.keys(userAssociatedData).map(dataType => (
                  <li key={dataType}>{dataType}</li>
                ))}
              </ul>
            </>
          )}

          <p>Type <strong>{userToDelete.displayName?.split(" ")[0]}</strong> to confirm deletion:</p>
          <input
            type="text"
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
            className="delete-confirmation-input"
            placeholder="Enter user's first name"
          />
        </EnhancedModal>
      )}
    </div>
  );
};

const condition = (authUser) =>
  authUser && authUser.isAdmin;


export default compose(
  withAuthorization(condition),
  withAuthentication,
  withFirebase
)(SimpleUsersTable);
