import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { updatedTerms } from './terms';


// Create styles
const styles = StyleSheet.create({
  documentStyle: {
    fontFamily: "Helvetica",
    backgroundColor: "#ffffff",
    color: "#000000",
  },
  page: {
    padding: 30,
    fontFamily: 'Helvetica'
  },
  section: {
    marginBottom: 10
  },

  mainTitle: {
    fontSize: 16,
    marginBottom: 30,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold'
  },

  title: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold'
  },
  body: {
    fontSize: 10,
    marginBottom: 10
  },
  checkbox: {
    marginTop: 10,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  checkmark: {
    marginLeft: 5,
    height: 15,
    width: 15,
    border: '1px solid black',
    alignItems: 'center',
    justifyContent: 'center'

  },
});



const TermsAndConditionsPDF = ({ username, documentTitle, signatureImage }) => (
  <Document title={documentTitle}>
    <Page size="A4" style={styles.page}>
      <Text style={styles.mainTitle}>Rehabilitation Enables Dreams, Inc. (dba RED) Terms and Conditions</Text>
      {updatedTerms.map((section, index) => (
        <View key={index} style={styles.section}>
          <Text style={styles.title}>{section.title}</Text>
          <Text style={styles.body}>{section.content}</Text>
          <View style={styles.checkbox}>
            <Text style={{ fontFamily: 'Helvetica-Bold', fontWeight: 'bold', fontSize: 10 }}>I have read and agree with the above terms and conditions.</Text>
            <View style={styles.checkmark}>
              <Image style={{ height: 10, width: 10 }} src=" https://cdn-icons-png.flaticon.com/512/5291/5291032.png" />
            </View>
          </View>
        </View>
      ))}
      <View>
        <Text style={{ fontSize: 10 }}>Name: {username}</Text>
        <Text style={{ fontSize: 10 }}>Signature</Text>
      </View>
      <View>
        <Image style={{ width: 300, height: 150 }} src={signatureImage} />
      </View>
    </Page>
  </Document>
);

export default TermsAndConditionsPDF;
